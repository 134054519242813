<template>
  <div class="flex h-full flex-col gap-1 overflow-auto p-2">
    <NotificationPanelItem
      v-for="notificationItem in notifications"
      :key="notificationItem.id"
      :notification-item="notificationItem"
      @click="() => onClickNotification(notificationItem)"
      @contextmenu="openContextMenu($event)"
      @mark-as-read="markAsRead"
      @mark-as-unread="markAsUnread"
    />
    <div
      v-if="showEmptyResult"
      class="surface-bg flex flex-grow flex-col items-center justify-center rounded-md p-8 text-center"
    >
      <img
        class="m-4 hidden w-64 dark:block"
        src="~dashboard/assets/images/no-tasks-dark.svg"
        alt="No Chat dark"
      />
      <img
        class="m-4 block w-64 dark:hidden"
        src="~dashboard/assets/images/no-tasks.svg"
        alt="No Chat"
      />
      <p class="txt-secondary -mt-4 text-2xl">
        {{ $t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.EMPTY_MESSAGE') }}
      </p>
      <p class="txt-muted text-sm">
        {{
          $t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.EMPTY_MESSAGE_SUBTITLE')
        }}
      </p>
    </div>
    <woot-button
      v-if="!isLoading && inLastPage"
      size="expanded"
      variant="clear"
      color-scheme="secondary"
      class-names="mt-2"
      @click="openNotificationPage"
    >
      {{ $t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.ALL_NOTIFICATIONS') }}
    </woot-button>
    <div
      v-if="isLoading"
      class="my-12 flex flex-grow flex-col items-center justify-center gap-2 text-sm"
    >
      <spinner />
      <span>{{
        $t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.LOADING_UNREAD_MESSAGE')
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Spinner from 'shared/components/Spinner.vue';
import NotificationPanelItem from './NotificationPanelItem.vue';

export default {
  components: {
    NotificationPanelItem,
    Spinner,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    onClickNotification: {
      type: Function,
      default: () => {},
    },
    inLastPage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      notificationMetadata: 'notifications/getMeta',
    }),

    showEmptyResult() {
      return !this.isLoading && this.notifications.length === 0;
    },
  },

  methods: {
    createdAtTime(date) {
      return this.shortTimestamp(this.dynamicTime(date));
    },

    openNotificationPage() {
      if (this.$route.name !== 'notifications_index') {
        this.$router.push({
          name: 'notifications_index',
        });
      }
      this.$emit('close');
    },

    markAsRead() {
      this.$emit('mark-as-read');
    },

    markAsUnread() {
      this.$emit('mark-as-unread');
    },
  },
};
</script>
