<template>
  <woot-modal
    :close-on-backdrop-click="false"
    :on-close="() => $emit('close')"
    :show="isVisible"
    overflow="auto"
    size="lg"
  >
    <formulate-form class="relative">
      <woot-modal-header :header-title="headerTitle" />
      <div
        class="entrypoint-modal-body modal-body flex flex-col flex-nowrap gap-6"
      >
        <formulate-input
          v-model="localEntrypoint.name"
          :label="$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.LABELS.NAME')"
          :placeholder="
            $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.PLACEHOLDERS.NAME')
          "
          class="entrypoint-modal-body-name"
          type="text"
          validation="required"
        />
        <woot-tabs
          class="border-b dark:border-gray-700"
          :index="activeTab"
          disable-scroll
          @change="onTabChange"
        >
          <woot-tabs-item
            v-for="tab in entrypointTabs"
            :key="tab.key"
            :icon="tab.icon"
            :icon-props="tab.iconProps"
            :name="tab.name"
            :show-badge="false"
            :class="editMode ? 'w-full' : 'flex-grow'"
          />
        </woot-tabs>
        <entrypoint-edit-api
          v-if="isAPIConfig"
          :current-step="currentAPIStep"
          :has-automations="hasAutomations"
          :merge-fields="localEntrypoint.mergeFields"
          :sample-data="localEntrypoint.sampleJSON"
          :show-warning="!validContactMergeFields"
          @update:JSON="onJSONUpdate"
          @update:merge-fields="onUpdateMergeFields"
        />
        <entrypoint-edit-list
          v-if="isListConfig"
          :selected-list="localEntrypoint.list"
          @update:list="onListUpdate"
        />
        <entrypoint-edit-scheduled
          v-if="isScheduledConfig"
          :cron-string="localEntrypoint.cronString"
          :edit-mode="editMode"
          @change-cron-string="onCronStringUpdate"
        />
      </div>
      <div class="modal-footer">
        <woot-button
          v-if="isAPIConfig && currentAPIStep > 0"
          class="mr-auto"
          color-scheme="secondary"
          icon="arrow-left"
          type="button"
          variant="smooth"
          @click="onGoBack"
        >
          {{ $t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.BUTTONS.BACK') }}
        </woot-button>
        <woot-button v-bind="nextBtnProps" @click="onGoNext">
          {{ nextBtnText }}
        </woot-button>
      </div>
    </formulate-form>
    <div
      v-if="isLoading"
      class="spinner-wrapper absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
    >
      <span class="spinner h-8 w-8" />
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import EntrypointEditApi from './EntrypointEditAPI';
import EntrypointEditList from './EntrypointEditList';
import EntrypointEditScheduled from './EntrypointEditScheduled.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { isJSONValid } from 'dashboard/helper/commons';
import { isXMLValid } from 'dashboard/helper/xmlHelpers';
import { updateInvalidMergeFields } from '../utils/automationLocalStorage';

export default {
  name: 'EntrypointEditModal',
  components: {
    EntrypointEditScheduled,
    EntrypointEditApi,
    EntrypointEditList,
  },
  mixins: [alertMixin],
  props: {
    entrypoint: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentAPIStep: 0,
      entrypointType: 'list',
      hasAutomations: false,
      isStepValid: false,
      isCronStringValid: false,
      localEntrypoint: {
        name: '',
        list: '',
        cronString: '',
        mergeFields: [],
        sampleJSON: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      entrypointUIFlags: 'entrypoint/getUIFlags',
    }),
    activeTab: {
      get() {
        return this.entrypointTabs.findIndex(
          (tab) => tab.key === this.entrypointType
        );
      },
      set(val) {
        this.entrypointType = this.entrypointTabs[val].key;
      },
    },
    editMode() {
      return !!this.entrypoint;
    },
    entrypointTabs() {
      const iconProps = {
        size: '16',
        viewBox: '0 0 16 16',
      };
      const tabs = [
        {
          key: 'list',
          name: this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.TABS.LIST'),
          icon: 'list-connected',
          iconProps,
        },
        {
          key: 'api',
          name: this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.TABS.API'),
          icon: 'api-connected',
          iconProps,
        },
        {
          key: 'scheduled',
          name: this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.TABS.SCHEDULED'),
          icon: 'timed',
          iconProps,
        },
      ];

      return this.editMode
        ? tabs.filter(({ key }) => key === this.entrypointType)
        : tabs;
    },
    nextBtnProps() {
      return {
        type: this.finalStep ? 'submit' : 'button',
        colorScheme: this.finalStep ? 'success' : 'primary',
        icon: this.finalStep ? '' : 'arrow-right',
        class: this.finalStep ? '' : 'flex-row-reverse',
        disabled: this.finalStep
          ? !(this.isValid && this.isDirty)
          : !this.isStepValid,
      };
    },
    validContactMergeFields() {
      const mergeFieldByType = (type) =>
        this.localEntrypoint.mergeFields.some(
          ({ attributeType }) => attributeType === type
        );
      return !mergeFieldByType('contact') || mergeFieldByType('phone');
    },
    headerTitle() {
      return this.editMode
        ? this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.TITLE.EDIT')
        : this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.TITLE.CREATE');
    },
    isAPIConfig() {
      return this.entrypointType === 'api';
    },
    isListConfig() {
      return this.entrypointType === 'list';
    },
    isScheduledConfig() {
      return this.entrypointType === 'scheduled';
    },
    isDirtyData() {
      return {
        api:
          JSON.stringify(this.entrypoint?.mergeFields) !==
          JSON.stringify(this.localEntrypoint.mergeFields),
        list: this.entrypoint?.list !== this.localEntrypoint?.list,
        scheduled:
          this.entrypoint?.cronString !== this.localEntrypoint?.cronString,
      };
    },
    isDirty() {
      const dirtyName = this.entrypoint?.name !== this.localEntrypoint.name;
      return dirtyName || this.isDirtyData[this.entrypointType];
    },
    isMergeFieldsFilled() {
      return this.localEntrypoint.mergeFields.every(
        ({
          attributeLabel,
          dateFormat,
          advNumPars,
          dateTimezoneShift,
          ...rest
        }) => {
          const filledProperties = Object.values(rest).every(
            (value) => value || value.toString()
          );
          const filledLabel =
            rest.attributeType !== 'automation' || !!attributeLabel;
          return filledProperties && filledLabel;
        }
      );
    },
    isValidAPIData() {
      return (
        this.localEntrypoint.sampleJSON &&
        (isJSONValid(this.localEntrypoint.sampleJSON) ||
          isXMLValid(this.localEntrypoint.sampleJSON))
      );
    },
    isValidAPI() {
      const isMergeFieldsValid =
        this.isMergeFieldsFilled && this.validContactMergeFields;
      return this.isValidAPIData && isMergeFieldsValid;
    },
    isValidData() {
      return {
        api: this.isValidAPI,
        list: !!this.localEntrypoint.list,
        scheduled: this.isCronStringValid,
      };
    },
    isValid() {
      return (
        !!this.localEntrypoint.name && this.isValidData[this.entrypointType]
      );
    },
    isLoading() {
      const { isCreating, isUpdating } = this.entrypointUIFlags;
      return isCreating || isUpdating;
    },
    finalStep() {
      return !this.isAPIConfig || this.currentAPIStep === 2;
    },
    nextBtnText() {
      const confirmButton = this.editMode
        ? this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.BUTTONS.EDIT')
        : this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.BUTTONS.CREATE');
      return this.finalStep
        ? confirmButton
        : this.$t('CHATLYN_AUTOMATIONS.ENTRYPOINT_MODAL.BUTTONS.NEXT');
    },
  },
  watch: {
    currentAPIStep(val) {
      const { mergeFields } = this.localEntrypoint;
      this.isStepValid = val ? !!mergeFields.length : this.isValidAPIData;
    },
  },
  mounted() {
    if (this.editMode) {
      this.entrypointType = this.getEntrypointType(this.entrypoint);
      this.setLocalEntrypoint(this.entrypoint);
    } else {
      this.localEntrypoint.automations = [];
    }
  },
  methods: {
    async handleFormSubmit() {
      this.setInvalidMergeFields();
      const action = this.editMode ? 'updateEntrypoint' : 'createEntrypoint';
      await this.onEntrypointAction(action);
    },
    onGoBack() {
      this.currentAPIStep -= 1;
    },
    onGoNext() {
      if (this.finalStep) {
        this.handleFormSubmit();
      } else {
        this.currentAPIStep += 1;
      }
    },
    onJSONUpdate({ sampleData, isValid }) {
      this.localEntrypoint.sampleJSON = sampleData;
      this.isStepValid = isValid;
    },
    onListUpdate(listId) {
      this.localEntrypoint.list = listId;
    },
    onCronStringUpdate({ cronString, valid }) {
      this.isCronStringValid = valid;
      this.localEntrypoint.cronString = cronString;
    },
    onUpdateMergeFields(fields) {
      this.localEntrypoint.mergeFields = [...fields];
      this.isStepValid = !!fields.length;
    },
    onTabChange(index) {
      this.activeTab = index;
    },
    getEntrypointType({ cronString = '', list = '' }) {
      if (list) return 'list';
      if (cronString) return 'scheduled';
      return 'api';
    },
    setInvalidMergeFields() {
      if (this.editMode && this.isAPIConfig && this.hasAutomations) {
        const automationIds = this.entrypoint.automations.map(({ _id }) => _id);
        updateInvalidMergeFields(
          this.localEntrypoint.mergeFields,
          this.entrypoint.mergeFields,
          automationIds
        );
      }
    },
    setLocalEntrypoint({
      name,
      automations,
      cronString = '',
      list = '',
      mergeFields = [],
      sampleJSON = '',
      _id,
    }) {
      this.localEntrypoint = {
        name,
        _id,
        mergeFields,
        ...(this.isListConfig && { list }),
        ...(this.isAPIConfig && { sampleJSON }),
        ...(this.isScheduledConfig && { cronString }),
      };
      this.currentAPIStep = mergeFields.length && sampleJSON ? 2 : 0;
      this.hasAutomations = !!automations.length;
    },
    getEntrypointPayload() {
      const { list, sampleJSON, cronString, ...rest } = this.localEntrypoint;
      return {
        ...rest,
        ...(this.isAPIConfig && { sampleJSON }),
        ...(this.isListConfig && { list }),
        ...(this.isScheduledConfig && { cronString }),
      };
    },
    redirectToNew({ _id: entrypointId }) {
      if (entrypointId && !this.editMode) {
        this.$router.replace({
          params: {
            accountId: this.currentAccountId,
            entrypointId,
          },
        });
      }
    },
    async onEntrypointAction(action) {
      try {
        const result = await this.$store.dispatch(`entrypoint/${action}`, {
          entrypoint: this.getEntrypointPayload(),
          accountId: this.currentAccountId,
        });

        await this.refetchEntrypoints();
        this.redirectToNew(result.data);
        const message =
          action === 'updateEntrypoint'
            ? this.$t('CHATLYN_AUTOMATIONS.NOTIFICATIONS.UPDATE_SUCCESS')
            : this.$t('CHATLYN_AUTOMATIONS.NOTIFICATIONS.CREATE_SUCCESS');
        this.showAlert(message);
      } catch (errorMsg) {
        this.showAlert(
          this.$t('CHATLYN_AUTOMATIONS.NOTIFICATIONS.ERROR', { errorMsg })
        );
      }
      this.$emit('close', this.editMode);
    },
    async refetchEntrypoints() {
      try {
        await this.$store.dispatch('entrypoint/getAllEntrypoint', {
          accountId: this.currentAccountId,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-top-bar {
  padding: 0;
  ::v-deep .page-sub-title {
    @apply m-0 font-normal;
  }
}
.spinner-wrapper {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>

<style lang="scss">
.entrypoint-modal-body {
  .tabs {
    @apply w-full p-0;
    .tabs-title {
      @apply m-0;
      a {
        @apply justify-center px-4 py-2;
      }
    }
  }
  &-name .formulate-input-errors {
    position: absolute;
    width: 100%;
  }
  .formulate-input-prepend-icon {
    position: relative;
    .formulate-input-icon {
      @apply absolute left-px z-10 flex items-center justify-center rounded-l-sm border-r bg-gray-50 dark:border-gray-700 dark:bg-gray-700;
      width: 38px;
      height: 38px;
      top: 25px;
    }
    input {
      padding-left: 46px;
    }
  }
}
</style>
