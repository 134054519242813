<template>
  <div class="modal-mask">
    <div
      v-on-clickaway="closeNotificationPanel"
      class="absolute left-14 z-10 m-4 flex h-[90vh] w-[32.5rem] flex-col justify-between rounded-md bg-white shadow-md rtl:left-auto rtl:right-14 dark:bg-gray-800"
    >
      <div
        class="flex w-full flex-row items-center justify-between border-b border-solid border-gray-50 py-4 pe-2 ps-4 dark:border-gray-700"
      >
        <div class="flex items-center">
          <span class="text-xl font-semibold text-gray-800 dark:text-gray-100">
            {{ $t('NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.TITLE') }}
          </span>

          <span
            v-if="totalUnreadNotifications"
            class="ml-2 mr-2 rounded-md bg-gray-50 px-2 py-1 text-xxs font-medium text-gray-700 dark:bg-gray-700 dark:text-gray-200"
          >
            {{ totalUnreadNotifications }}
          </span>
        </div>

        <div class="flex items-center gap-1">
          <woot-button
            v-if="getUnreadNotifications.length > 0"
            v-tooltip="{
              content: $t('NOTIFICATIONS_PAGE.MARK_ALL_DONE'),
              position: 'bottom',
            }"
            color-scheme="success"
            size="small"
            :is-loading="uiFlags.isUpdating"
            icon="checkmark-double"
            @click="onMarkAllDoneClick"
          />

          <woot-button
            v-tooltip="{
              content: $t('CONVERSATION.FOOTER.CLICK_HERE'),
              position: 'bottom',
            }"
            color-scheme="secondary"
            variant="smooth"
            size="small"
            icon="settings"
            @click="openAudioNotificationSettings"
          />

          <woot-button
            color-scheme="secondary"
            variant="link"
            class="small"
            icon="dismiss"
            @click="closeNotificationPanel"
          />
        </div>
      </div>

      <notification-panel-list
        :notifications="getUnreadNotifications"
        :is-loading="uiFlags.isFetching"
        :on-click-notification="openConversation"
        :in-last-page="inLastPage"
        @close="closeNotificationPanel"
      />

      <div
        v-if="getUnreadNotifications.length !== 0"
        class="flex items-center justify-between px-5 py-1"
      >
        <div class="flex">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            :is-disabled="inFirstPage"
            @click="onClickFirstPage"
          >
            <fluent-icon icon="chevron-left" size="16" />

            <fluent-icon icon="chevron-left" size="16" class="-ml-4" />
          </woot-button>

          <woot-button
            color-scheme="secondary"
            variant="clear"
            :disabled="inFirstPage"
            @click="onClickPreviousPage"
          >
            <fluent-icon icon="chevron-left" size="16" />
          </woot-button>
        </div>

        <span class="text-xxs font-medium text-gray-500 dark:text-gray-400">
          {{ currentPage }} - {{ lastPage }}
        </span>

        <div class="flex">
          <woot-button
            color-scheme="secondary"
            variant="clear"
            :disabled="inLastPage"
            @click="onClickNextPage"
          >
            <fluent-icon icon="chevron-right" size="16" />
          </woot-button>

          <woot-button
            variant="clear"
            color-scheme="secondary"
            :disabled="inLastPage"
            @click="onClickLastPage"
          >
            <fluent-icon icon="chevron-right" size="16" />

            <fluent-icon icon="chevron-right" size="16" class="-ml-4" />
          </woot-button>
        </div>
      </div>

      <div v-else />

      <canvas
        ref="confetti-container"
        class="pointer-events-none absolute left-0 top-0 h-full w-full border-0 bg-transparent"
      />
    </div>
  </div>
</template>

<script>
// Libs //
import { mapGetters } from 'vuex';

// Mixins //
import rtlMixin from 'shared/mixins/rtlMixin';
import confettiMixin from 'shared/mixins/confettiMixin';

// Components //
import NotificationPanelList from './NotificationPanelList.vue';

// Constants //
import { ACCOUNT_EVENTS } from '../../../../helper/AnalyticsHelper/events';

export default {
  components: {
    NotificationPanelList,
  },

  mixins: [rtlMixin, confettiMixin],

  data() {
    return {
      jsConfetti: null,
      pageSize: 15,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      meta: 'notifications/getMeta',
      records: 'notifications/getNotifications',
      uiFlags: 'notifications/getUIFlags',
    }),

    notificationPanelFooterIconClass() {
      return this.isRTLView ? '-mr-3' : '-ml-3';
    },

    totalUnreadNotifications() {
      return this.meta.unreadCount;
    },

    noUnreadNotificationAvailable() {
      return this.meta.unreadCount === 0;
    },

    getUnreadNotifications() {
      return this.records.filter(
        (notification) => notification.read_at === null
      );
    },

    currentPage() {
      return Number(this.meta.currentPage);
    },

    lastPage() {
      if (this.totalUnreadNotifications > 15) {
        return Math.ceil(this.totalUnreadNotifications / this.pageSize);
      }
      return 1;
    },

    inFirstPage() {
      const page = Number(this.meta.currentPage);
      return page === 1;
    },

    inLastPage() {
      return this.currentPage === this.lastPage;
    },
  },

  watch: {
    'getUnreadNotifications.length': {
      handler(newValue, oldValue) {
        if (!newValue && oldValue) {
          this.showConfetti();
        }
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      const confettiContainerElement = this.$refs['confetti-container'];

      if (!confettiContainerElement) {
        return;
      }

      this.setupConfetti({
        canvas: confettiContainerElement,
      });
    });

    this.$store.dispatch('notifications/get', { page: 1 });
  },

  beforeDestroy() {
    this.destroyConfetti();
  },

  methods: {
    onPageChange(page) {
      this.$store.dispatch('notifications/get', { page });
    },

    openConversation(notification) {
      const {
        primary_actor_id: primaryActorId,
        primary_actor_type: primaryActorType,
        primary_actor: { id: conversationId },
        notification_type: notificationType,
      } = notification;

      this.$track(ACCOUNT_EVENTS.OPEN_CONVERSATION_VIA_NOTIFICATION, {
        notificationType,
      });

      this.$store.dispatch('notifications/read', {
        id: notification.id,
        primaryActorId,
        primaryActorType,
        unreadCount: this.meta.unreadCount,
      });

      const { params, name } = this.$route;

      if (
        name !== 'inbox_conversation' ||
        params.conversation_id !== `${conversationId}` ||
        params.accountId !== `${this.accountId}`
      ) {
        this.$router.push({
          name: 'inbox_conversation',
          params: { conversation_id: conversationId },
        });
      }

      this.$emit('close');
    },

    onClickNextPage() {
      if (!this.inLastPage) {
        const page = this.currentPage + 1;
        this.onPageChange(page);
      }
    },

    onClickPreviousPage() {
      if (!this.inFirstPage) {
        const page = this.currentPage - 1;
        this.onPageChange(page);
      }
    },

    onClickFirstPage() {
      if (!this.inFirstPage) {
        const page = 1;
        this.onPageChange(page);
      }
    },

    onClickLastPage() {
      if (!this.inLastPage) {
        const page = this.lastPage;
        this.onPageChange(page);
      }
    },

    async onMarkAllDoneClick() {
      await this.$track(ACCOUNT_EVENTS.MARK_AS_READ_NOTIFICATIONS);
      await this.$store.dispatch('notifications/readAll');
    },

    openAudioNotificationSettings() {
      const { name } = this.$route;

      if (name !== 'profile_settings_index') {
        this.$router.push({
          name: 'profile_settings_index',
          query: { tab: '3' },
        });
      }

      this.closeNotificationPanel();
    },

    closeNotificationPanel() {
      this.$emit('close');
    },
  },
};
</script>
