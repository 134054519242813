<template>
  <div class="flex flex-grow flex-col bg-gray-50 dark:bg-gray-950">
    <automations-header
      hide-create-button
      :header-title="$t('CHATLYN_AUTOMATIONS.AUTOMATIONS')"
    />

    <entrypoint :entrypoint-id="entrypointId" />

    <entrypoints-empty-state
      v-if="isNoEntrypoint && !isLoading"
      @toggle-is-create-modal-shown="toggleIsCreateModalShown"
    />

    <entrypoint-edit-modal
      v-if="isCreateModalShown"
      :is-visible="isCreateModalShown"
      @close="toggleIsCreateModalShown"
    />

    <div
      v-if="isLoading"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
    >
      <span class="spinner h-8 w-8" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AutomationsHeader from '../../messageTemplates/components/MessageTemplatesHeader.vue';
import EntrypointEditModal from '../components/EntrypointEditModal.vue';
import Entrypoint from '../components/Entrypoint';
import EntrypointsEmptyState from '../components/EntrypointsEmptyState.vue';
import { cleanUpAutomationsData } from '../utils/automationLocalStorage';

export default {
  name: 'Automations',
  components: {
    EntrypointsEmptyState,
    EntrypointEditModal,
    AutomationsHeader,
    Entrypoint,
  },
  props: {
    entrypointId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCreateModalShown: false,
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      allEntrypoint: 'entrypoint/getAllEntrypoint',
      entrypointUIFlags: 'entrypoint/getUIFlags',
    }),
    automationIds() {
      return this.allEntrypoint
        .map((entrypoint) => entrypoint?.automations || [])
        .flat();
    },

    isLoading() {
      return this.entrypointUIFlags?.isFetching;
    },

    isNoEntrypoint() {
      return !this.allEntrypoint?.length;
    },

    isNoEntrypointSelected() {
      return !this.entrypointId && !this.isNoEntrypoint;
    },
  },
  watch: {
    entrypointId(newValue) {
      if (!newValue) {
        this.initialSetup();
      }
    },
  },
  async mounted() {
    await this.initialSetup();
    cleanUpAutomationsData(this.automationIds);
  },
  methods: {
    toggleIsCreateModalShown() {
      this.isCreateModalShown = !this.isCreateModalShown;
    },

    async initialSetup(isSecondary = false) {
      if (!isSecondary) {
        await this.$store.dispatch('entrypoint/getAllEntrypoint', {
          accountId: this.currentAccountId,
        });
      }

      if (this.isNoEntrypointSelected) {
        this.$router.replace({
          name: 'automations_dashboard_entrypoint',
          params: {
            accountId: this.currentAccountId,
            // eslint-disable-next-line no-underscore-dangle
            entrypointId: this.allEntrypoint[0]._id,
          },
        });
        return;
      }

      if (
        this.allEntrypoint.findIndex(
          // eslint-disable-next-line no-underscore-dangle
          (value) => value._id === this.entrypointId
        ) === -1
      ) {
        this.$router.replace({
          name: 'automations_dashboard',
          params: {
            accountId: this.currentAccountId,
          },
        });
      }
    },
  },
};
</script>

<style></style>
