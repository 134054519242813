import { BrandnamicApi } from 'dashboard/api/brandnamic';
import types from '../../mutation-types';

export const actions = {
  fetchBrandnamicContact: async ({ commit }, { id, body }) => {
    try {
      const { data } = await BrandnamicApi.fetchBrandnamicContacts(id, body);

      commit(types.SET_BRANDNAMIC_CONTACT, data.contacts[0]);
    } catch (error) {
      console.log(error);
    }
  },
  fetchBrandnamicContacts: async ({ commit }, { id, body }) => {
    try {
      const { data } = await BrandnamicApi.fetchBrandnamicContacts(id, body);

      commit(types.SET_BRANDNAMIC_CONTACTS, data.contacts);
    } catch (error) {
      console.log(error);
    }
  },
};
