/* global axios */

import { stagingUrl } from 'dashboard/api/automation.stub';
import JwtAuth from 'dashboard/api/jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? stagingUrl
    : window.location.protocol + '//' + window.location.hostname;

export const BrandnamicApi = {
  fetchBrandnamicContacts: async (id, body) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/partnersmw/brandnamic/guestProfile/account/${id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  },
};
