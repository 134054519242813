// Libs //
import JSConfetti from 'js-confetti';

export default {
  computed: {
    // jsConfetti must be defined in component
    jsConfettiComputed: {
      get() {
        return this.jsConfetti;
      },

      set(newValue) {
        this.jsConfetti = newValue;
      },
    },
  },

  methods: {
    setupConfetti(options) {
      this.$nextTick(() => {
        this.jsConfettiComputed = new JSConfetti(options);
      });
    },

    showConfetti() {
      this.jsConfettiComputed.addConfetti();
    },

    destroyConfetti() {
      this.jsConfettiComputed.destroyCanvas();
    },
  },
};
