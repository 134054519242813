import types from '../../mutation-types';

export const mutations = {
  [types.SET_BRANDNAMIC_CONTACTS]: (state, payload) => {
    state.brandnamicContacts = payload;
  },
  [types.SET_BRANDNAMIC_CONTACT]: (state, payload) => {
    state.brandnamicContact = payload;
  },
};
