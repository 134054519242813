// import axios from 'axios';
import types from '../../mutation-types';
import ManagementAPI from 'dashboard/api/management.js';

export const actions = {
  getUserLanguage: async ({ commit }, { currentUserId }) => {
    try {
      const response = await ManagementAPI.getUserLanguage(currentUserId);

      if (!response.data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_USER_LANGUAGE, response.data);
    } catch (error) {
      // TODO some action
    }
  },

  // DEPRECATED
  // setUserLanguage: async ({ commit }, { currentUserId, language }) => {
  //   try {
  //     await ManagementAPI.setUserLanguage(currentUserId, language);
  //     commit(types.SET_MANAGEMENT_USER_LANGUAGE, language);
  //   } catch (error) {
  //     // TODO some action
  //   }
  // },

  getUserProfileDetails: async ({ commit }, { userId }) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MANAGEMENT_USER_PROFILE_DETAILS);

      const { data } = await ManagementAPI.getUserProfileDetails(userId);

      if (!data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_USER_PROFILE_DETAILS, data);
    } catch (error) {
      // throw new Error(error);
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: false });
    }
  },

  updateUserProfileDetails: async ({ commit }, { userId, newDetails }) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isUpdating: true });

      const { data } = await ManagementAPI.updateUserProfileDetails(
        userId,
        newDetails
      );

      if (!data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_USER_PROFILE_DETAILS, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isUpdating: false });
    }
  },

  getAccount: async ({ commit }, { accountId }) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: true });

      const response = await ManagementAPI.getAccount(accountId);

      if (!response.data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_ACCOUNT, response.data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: false });
    }
  },

  updateAccount: async ({ commit }, { accountId, account }) => {
    try {
      const response = await ManagementAPI.updateAccount(accountId, account);

      if (!response.data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_ACCOUNT, response.data);
    } catch (error) {
      // TODO some action
    }
  },

  getChatBubbles: async ({ commit }, { inboxId, lang }) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MANAGEMENT_CHAT_BUBBLES);
      const response = await ManagementAPI.getChatBubbles(inboxId, lang);

      if (!response.data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_CHAT_BUBBLES, response.data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: false });
    }
  },

  createChatBubbles: async (
    { commit },
    { inboxId, accountId, chatBubbles }
  ) => {
    let isSuccess = false;

    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isCreating: true });

      await ManagementAPI.createChatBubbles(inboxId, accountId, chatBubbles);
      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isCreating: false });
    }

    return isSuccess;
  },

  updateChatBubbles: async (
    { commit },
    { inboxId, accountId, chatBubbles }
  ) => {
    let isSuccess = false;

    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isUpdating: true });

      await ManagementAPI.updateChatBubbles(inboxId, accountId, chatBubbles);
      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isUpdating: false });
    }

    return isSuccess;
  },

  deleteChatBubble: async ({ commit }, { inboxId, accountId, id }) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isDeleting: true });

      await ManagementAPI.deleteChatBubble(inboxId, accountId, id);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isDeleting: false });
    }
  },

  deleteLanguageChatBubbles: async (
    { commit },
    { inboxId, accountId, language }
  ) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isDeleting: true });

      await ManagementAPI.deleteLanguageChatBubbles(
        inboxId,
        accountId,
        language
      );
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isDeleting: false });
    }
  },

  getSignatures: async ({ commit }, { userId }) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MANAGEMENT_SIGNATURES);
      const response = await ManagementAPI.getSignatures(userId);

      if (!response.data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_SIGNATURES, response.data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: false });
    }
  },

  createSignature: async ({ commit }, { userId, accountId, signature }) => {
    let isSuccess = true;
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isCreating: true });

      await ManagementAPI.createSignature(userId, accountId, signature);
    } catch (error) {
      isSuccess = false;
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isCreating: false });
    }

    return isSuccess;
  },

  updateSignature: async ({ commit }, { userId, accountId, signature }) => {
    let isSuccess = false;

    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isUpdating: true });

      await ManagementAPI.updateSignature(userId, accountId, signature);
      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isUpdating: false });
    }

    return isSuccess;
  },

  deleteSignature: async ({ commit }, { userId, signatureId }) => {
    let isSuccess = false;

    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isDeleting: true });

      await ManagementAPI.deleteSignature(userId, signatureId);
      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isDeleting: false });
    }

    return isSuccess;
  },

  getInboxOptOut: async ({ commit }, { id }) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_MANAGEMENT_INBOX_OPT_OUT);

      const response = await ManagementAPI.getInboxOptOut(id);

      if (!response.data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_INBOX_OPT_OUT, response.data);
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isFetching: false });
    }
  },

  updateInboxOptOut: async ({ commit }, { inboxOptOut }) => {
    let isSuccess = false;

    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isUpdating: true });

      await ManagementAPI.updateInboxOptOut(inboxOptOut);
      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isUpdating: false });
    }

    return isSuccess;
  },

  getInboxOptOuts: async (
    { commit },
    { inboxId, query, sort, page, limit }
  ) => {
    let isSuccess = false;

    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isOptOutsFetching: true });
      commit(types.CLEAR_MANAGEMENT_INBOX_OPT_OUTS);

      const { data } = await ManagementAPI.getInboxOptOuts(
        inboxId,
        query,
        sort,
        page,
        limit
      );

      commit(types.SET_MANAGEMENT_INBOX_OPT_OUTS, data);
      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isOptOutsFetching: false });
    }

    return isSuccess;
  },

  updateInboxOptOuts: async ({ commit }, { inboxId, optOuts }) => {
    let isSuccess = false;

    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isOptOutsUpdating: true });

      await ManagementAPI.updateInboxOptOuts(inboxId, optOuts);
      isSuccess = true;
    } catch (error) {
      // TODO some action
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isOptOutsUpdating: false });
    }

    return isSuccess;
  },

  getSms: async ({ commit }, { accountId }) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isSmsFetching: true });
      commit(types.CLEAR_MANAGEMENT_SMS);

      const { data } = await ManagementAPI.getSms(accountId);

      if (!data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_SMS, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isSmsFetching: false });
    }
  },

  updateSms: async ({ commit }, { accountId, sms }) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isSmsUpdating: true });

      const { data } = await ManagementAPI.updateSms(accountId, sms);

      if (!data) {
        throw new Error();
      }

      commit(types.SET_MANAGEMENT_SMS, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_MANAGEMENT_UI_FLAGS, { isSmsUpdating: false });
    }
  },

  setUiFlag: ({ commit }, newFlags) => {
    try {
      commit(types.SET_MANAGEMENT_UI_FLAGS, newFlags);
    } catch (error) {
      // TODO some action
    }
  },
};
